import React from "react";

const SuccessContainer = () => (
  <>
    <p className="text-left">
      You can now use your new password to login to you account.
    </p>
  </>
);

export default SuccessContainer;
