import React from "react";

// components
import FormInput from "../../../../components/FormInput";
import { Input, Label } from "reactstrap";

const InnerForm = ({ loading }) => {
  return (
    <>
      <div className="form-field-wrapper">
        <Label>Email</Label>
        <FormInput
          disabled={loading}
          type="email"
          name="email"
          placeholder="you@example.com"
          component={Input}
        />
      </div>
    </>
  );
};

export default InnerForm;
