import React from "react";

import { useField, useFormikContext } from "formik";

const FormInput = ({ name, component: Component, type, ...rest }) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);

  const shouldRenderError =
    meta.error && meta.touched && formik.submitCount > 0;

  const getFieldProps = () => {
    if (type !== "checkbox") {
      return { ...field, ...rest, type };
    }

    return { ...field, ...rest, type, checked: field.value };
  };

  return (
    <>
      <Component {...getFieldProps()} />
      {shouldRenderError && (
        <div className="input-error-message">{meta.error}</div>
      )}
    </>
  );
};

export default FormInput;
