import React, { useContext, useEffect } from "react";

// steps
import FirstStep from "./first-step";
import FirstStepSuccess from "./first-step-success";
import ResetPassword from "./reset-password";

import { AuthContext } from "../../../context/auth-context";
import { useLocation } from "react-router-dom";
import { getQueryVariable } from "../../../utils/general";

const ForgotPassword = () => {
  const location = useLocation();
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "INIT_AUTH_CONTEXT" });
  }, []);

  useEffect(() => {
    const { search } = location;

    if (!search) return;
    const codeValue = getQueryVariable("code");

    if (!codeValue) return;

    dispatch({
      type: "SET_FORGOT_PASSWORD_STEP",
      payload: { step: 3, code: codeValue },
    });
  }, []);

  const { state } = useContext(AuthContext);
  const { forgotPasswordStep } = state;

  const renderStepComponent = (step) => {
    switch (step) {
      case 1:
        return <FirstStep />;
      case 2:
        return <FirstStepSuccess />;
      case 3:
        return <ResetPassword />;
      default:
        return null;
    }
  };

  return <>{renderStepComponent(forgotPasswordStep)}</>;
};

export default ForgotPassword;
