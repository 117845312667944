import React, { useContext, useState, useEffect } from "react";

// helpers
import auth from "../../utils/auth";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";

// Components
import {
  Button,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";

import "./style.scss";

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();
  const { state, dispatch } = useContext(AuthContext);
  const [isNavOpen, setOpenNav] = useState(false);

  const isAppInitialized = () => state.initialized;
  const isUserAuthorized = () => state.token && state.user;

  useEffect(() => {
    isNavOpen && setOpenNav(false);
  }, [location.pathname]);

  const handleLogout = () => {
    auth.clearToken();
    dispatch({ type: "LOGOUT" });
  };

  const renderBrand = () => (
    <NavbarBrand href="https://cartan.group">
      <h1>CARTAN </h1>{" "}
      <span className="text-white">
        360&deg;
        <sub>
          <small>beta</small>
        </sub>
      </span>
    </NavbarBrand>
  );

  const renderHeaderAuthSection = () => (
    <>
      <NavItem className="header-auth-buttons-wrapper">
        <Button
          outline
          className="sign-in-button"
          onClick={() => history.push("/auth/login")}
        >
          Login
        </Button>
      </NavItem>
      <NavItem className="header-auth-buttons-wrapper">
        <Button
          outline
          className="sign-in-button-primary"
          onClick={() => history.push("/auth/request-access")}
        >
          Request Access
        </Button>
      </NavItem>
    </>
  );

  const renderAuthorizedSection = () => (
    <>
      <NavItem className="header-auth-buttons-wrapper">
        <Button outline className="sign-in-button" onClick={handleLogout}>
          Log out
        </Button>
      </NavItem>
    </>
  );

  const renderRouteLinks = () => {
    const navItems = [
      { path: "/", name: "Home" },
      { path: "/dashboard", name: "Dashboard" },
      { path: "/entities", name: "Entities" },
    ];

    return (
      <>
        {navItems.map(({ path, name }) => (
          <NavItem key={path}>
            <NavLink tag={Link} to={path} active={location.pathname === path}>
              {name}
            </NavLink>
          </NavItem>
        ))}
      </>
    );
  };

  return (
    <header className="general-header-bar">
      <Navbar className="navbar-sticky nav" expand="lg">
        {renderBrand()}

        <NavbarToggler onClick={() => setOpenNav((status) => !status)}>
          <span className="fa fa-align-justify header-toggle-icon" />
        </NavbarToggler>

        <Collapse isOpen={isNavOpen} navbar className="collapse-wrapper">
          <Nav className="ml-auto" navbar>
            {renderRouteLinks()}
            {isAppInitialized() &&
              (isUserAuthorized()
                ? renderAuthorizedSection()
                : renderHeaderAuthSection())}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default Navigation;
