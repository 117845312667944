import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./style.scss"; 

class TablePagination extends React.Component {
  constructor(props) {
    super(props);

    this.onPageSelected = this.onPageSelected.bind(this);
  }

  renderPaginationItems() {
    const pageRange = Math.floor(5 / 2);

    let rangeStart = this.props.currentPage - pageRange;
    let rangeEnd = this.props.currentPage + pageRange;

    if (rangeEnd > this.props.lastPage) {
      rangeEnd = this.props.lastPage;
      rangeStart = rangeEnd - pageRange * 2;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;
    }

    if (rangeStart < 1) {
      rangeStart = 1;
      rangeEnd = Math.min(pageRange * 2 + 1, this.props.lastPage);
    }

    const result = [];
    let i = 0;
    for (
      let redirectTo = rangeStart;
      redirectTo <= rangeEnd;
      redirectTo++, i++
    ) {
      result.push(
        <PaginationItem key={i} active={redirectTo === this.props.currentPage}>
          <PaginationLink
            className={`digits-${redirectTo.toString().length}`}
            onClick={() => this.onPageSelected(redirectTo)}
          >
            {redirectTo}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return result;
  }

  onPageSelected(page) {
    if (page !== this.props.currentPage) {
      this.props.onPageSelected(page);
    }
  }

  render() {
    return (
      <div>
        <div>
          <Pagination className=" end">
            {!(this.props.currentPage - 3 <= 1) && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => this.onPageSelected(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {!(this.props.currentPage - 23 <= 1) && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() =>
                        this.onPageSelected(this.props.currentPage - 25 || 1)
                      }
                    >
                      Previous 25
                    </PaginationLink>
                  </PaginationItem>
                )}
                {!(this.props.currentPage - 3 <= 1) && (
                  <PaginationItem>
                    <PaginationLink
                      previous
                      onClick={() =>
                        this.onPageSelected(this.props.currentPage - 5 || 1)
                      }
                    >
                      {"<<"}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() =>
                      this.onPageSelected(this.props.currentPage - 1)
                    }
                  >
                    ‹
                  </PaginationLink>
                </PaginationItem>
              </>
            )}

            {this.renderPaginationItems()}

            {!(this.props.currentPage + 4 > this.props.lastPage) && (
              <>
                <PaginationItem>
                  <PaginationLink
                    next
                    onClick={() =>
                      this.onPageSelected(this.props.currentPage + 1)
                    }
                  >
                    ›
                  </PaginationLink>
                </PaginationItem>

                {!(this.props.currentPage + 5 > this.props.lastPage) && (
                  <PaginationItem>
                    <PaginationLink
                      next
                      onClick={() =>
                        this.onPageSelected(this.props.currentPage + 5)
                      }
                    >
                      {">>"}
                    </PaginationLink>
                  </PaginationItem>
                )}
                {!(this.props.currentPage + 25 > this.props.lastPage) && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() =>
                        this.onPageSelected(this.props.currentPage + 25)
                      }
                    >
                      Next 25
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => this.onPageSelected(this.props.lastPage)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
          </Pagination>
        </div>
      </div>
    );
  }
}

export default TablePagination;
