import React, { useEffect, useContext } from "react";

// helpers
import useBrowserTabTitle from "../../hooks/useBrowserTabTitle";
import { Switch, Route } from "react-router-dom";
import auth from "../../utils/auth";
import jwt_decode from "jwt-decode";
import { authAPI } from "../../api/authAPI";
import { AuthContext } from "../../context/auth-context";

// components
import { Spinner } from "reactstrap";
import Navigation from "../../components/Navigation";
import AuthPage from "../../containers/auth";
import EntityPage from "../../containers/entities";
import HomePage from "../../containers/home";
import DashboardPage from "../../containers/dashboard";
import PrivateRoute from "../../containers/private-route";
import Footer from "../../components/Footer";

// Design
import "./app.css";

const App = () => {
  const { state, dispatch } = useContext(AuthContext);

  const browserTabTitle = useBrowserTabTitle(!!(state.token && state.user));

  useEffect(() => {
    document.title = browserTabTitle;
  }, [browserTabTitle]);

  useEffect(() => {
    const jwtToken = auth.getToken();

    if (jwtToken) {
      const currentDate = new Date();
      const isTokenActive =
        jwt_decode(jwtToken).exp <
        currentDate.getTime() - jwt_decode(jwtToken).iat;
      if (isTokenActive) {
        getCurrentUserInfo();
      } else {
        auth.clearToke();
        dispatch({ type: "LOGOUT" });
      }
    } else {
      dispatch({
        type: "SET_INITIALIZED",
        payload: { status: true },
      });
    }
  }, []);

  const getCurrentUserInfo = async () => {
    dispatch({ type: "GET_ME_REQUEST" });

    try {
      const token = auth.getToken();

      const response = await authAPI.getMe();
      dispatch({
        type: "GET_ME_SUCCESS",
        payload: { user: response, token },
      });
    } catch (err) {
      if (err && err.response) {
        const { response } = err;
        const errorMessage = response.data.message[0].messages[0].message;
        dispatch({ type: "GET_ME_ERROR", payload: { error: errorMessage } });
      }
    }

    dispatch({
      type: "SET_INITIALIZED",
      payload: { status: true },
    });
  };

  return !state.initialized ? (
    <div className="main-spinner-wrapper">
      <Spinner style={{ width: "3rem", height: "3rem", color: "#213731" }} />
    </div>
  ) : (
    <div className="App">
      <Navigation />
      <div className="content-section">
        <Switch>
          <Route path="/" component={HomePage} exact />
          <PrivateRoute path="/auth/:authType" component={AuthPage} isAuth />
          <PrivateRoute path="/entities" component={EntityPage} />
          <PrivateRoute exact path="/dashboard" component={DashboardPage} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default App;
