import { apiConfig } from "../config/api-config";
import axiosInstance from "./axios-instance";

const basePath = `${apiConfig.apiGateway}/auth`;

const authAPI = {
  getMe: () => {
    return axiosInstance
      .get(`${apiConfig.apiGateway}/users/me`)
      .then((response) => response.data);
  },
  login: (body) => {
    return axiosInstance
      .post(`${basePath}/local`, body)
      .then((response) => response.data);
  },
  requestAccess: (body) => {
    return axiosInstance
      .post(`${basePath}/local/register`, body)
      .then((response) => response.data);
  },
  forgotPasswordSendEmail: (body) => {
    return axiosInstance
      .post(`${basePath}/forgot-password`, body)
      .then((response) => response.data);
  },
  resetPassword: (body) => {
    return axiosInstance
      .post(`${basePath}/reset-password`, body)
      .then((response) => response.data);
  },
};

const authAPIHelpers = {
  login: ({ email: identifier, password }) => ({ identifier, password }),
  requestAccess: ({
    name,
    company,
    phone,
    email,
    addNewsLetter,
    password,
  }) => ({
    name,
    username: email,
    company,
    phone,
    email,
    addNewsLetter,
    password,
  }),
};

export { authAPI, authAPIHelpers };
