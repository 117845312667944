import React from "react";

// components
import FormInput from "../../../components/FormInput";
import { Input, Label } from "reactstrap";

const InnerForm = ({ isLoading }) => (
  <>
    <div className="row mb-2">
      <div className="form-field-wrapper col-xl-6 col-lg-6 col-md-12">
        <Label>Name</Label>
        <FormInput
          disabled={isLoading}
          type="text"
          name="name"
          placeholder="Enter name"
          component={Input}
        />
      </div>

      <div className="form-field-wrapper col-xl-6 col-lg-6 col-md-12">
        <Label>Company</Label>
        <FormInput
          disabled={isLoading}
          type="text"
          name="company"
          placeholder="Enter company"
          component={Input}
        />
      </div>
    </div>

    <div className="row mb-2">
      <div className="form-field-wrapper col-xl-6 col-lg-6 col-md-12">
        <Label>Phone</Label>
        <FormInput
          disabled={isLoading}
          type="text"
          name="phone"
          placeholder="Enter phone"
          component={Input}
        />
      </div>

      <div className="form-field-wrapper col-xl-6 col-lg-6 col-md-12">
        <Label>Email</Label>
        <FormInput
          disabled={isLoading}
          type="email"
          name="email"
          placeholder="Enter email"
          component={Input}
        />
      </div>
    </div>

    <div className="row mb-2">
      <div className="form-field-wrapper col-xl-6 col-lg-6 col-md-12">
        <Label>Password</Label>
        <FormInput
          disabled={isLoading}
          type="password"
          name="password"
          placeholder="Enter password"
          component={Input}
        />
      </div>

      <div className="form-field-wrapper col-xl-6 col-lg-6 col-md-12">
        <Label>Confirm Password</Label>
        <FormInput
          disabled={isLoading}
          type="password"
          name="passwordConfirm"
          placeholder="Enter password confirm"
          component={Input}
        />
      </div>
    </div>

    <div className="form-field-wrapper">
      <FormInput
        disabled={isLoading}
        className="checkbox-field"
        type="checkbox"
        name="addNewsLetter"
        component={Input}
      />
      <Label className="checkbox-label">
        Stay up-to-date with our market insights delivered to your inbox.
      </Label>
    </div>
  </>
);

export default InnerForm;
