import React, { useState, useEffect } from "react";

// helpers

import useFetch from "../../hooks/useFetch";
import { debounce } from "lodash";
import { entitiesAPI } from "../../api/entitiesAPI";
import { TABLE_RESULT_LIMIT } from "../../config/constants";
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../config/constants";

import { getQueryVariable } from "../../utils/general";

// components
import { Table, Spinner } from "reactstrap";
import FilterBar from "./filter-bar";
import TablePagination from "../../components/TablePagination";
import TableLoader from "../../components/TableLoader";
import TableEmpty from "../../components/TableEmpty";

import "./styles.scss";

const Entities = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [entities, setEntities] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [entityCountries, setEntityCountries] = useState([]);

  const [licenseType, setLicenseType] = useState("");
  const [entityCountry, setEntityCountry] = useState("");
  const [searchNameQuery, setSearchNameQuery] = useState("");
  const [searchReferenceNumber, setSearchReferenceNumber] = useState("");

  useEffect(() => {
    const countryFilter = getQueryVariable("country");
    const licenseTypeFilter = getQueryVariable("license-type");

    countryFilter && setEntityCountry(countryFilter);
    licenseTypeFilter && setLicenseType(licenseTypeFilter);
  }, []);

  const {
    response: getEntitiesResponse,
    loading: getEntitiesLoader,
  } = useFetch(() => {
    let params = {
      _limit: TABLE_RESULT_LIMIT,
      _start: (currentPage - 1) * TABLE_RESULT_LIMIT,
      Name_contains: searchNameQuery,
      RegistrationNumber_contains: searchReferenceNumber,
    };

    if (licenseType) {
      params = { ...params, LicenseType_eq: licenseType };
    }

    if (entityCountry) {
      params = { ...params, Country_eq: entityCountry };
    }

    return entitiesAPI.getEntities(params);
  }, [
    currentPage,
    searchNameQuery,
    searchReferenceNumber,
    licenseType,
    entityCountry,
  ]);

  const {
    response: getEntitiesTotalResponse,
    loading: getEntitiesTotalLoader,
  } = useFetch(() => {
    if (!getEntitiesResponse) return;

    let params = {
      Name_contains: searchNameQuery,
      RegistrationNumber_contains: searchReferenceNumber,
    };

    if (licenseType) {
      params = { ...params, LicenseType_eq: licenseType };
    }

    if (entityCountry) {
      params = { ...params, Country_eq: entityCountry };
    }

    return entitiesAPI.getEntitiesTotal(params);
  }, [getEntitiesResponse]);

  const {
    response: getLicensedTypesResponse,
    loading: getEntityLicenseLoader,
  } = useFetch(() => entitiesAPI.getEntityLicenseTypes());

  const {
    response: getLicensedCountriesResponse,
    loading: getLicensedCountriesLoader,
  } = useFetch(() => entitiesAPI.getEntityLicenseCountries());

  useEffect(() => {
    setEntities(getEntitiesResponse || []);
  }, [getEntitiesResponse]);

  useEffect(() => {
    setTotalCount(getEntitiesTotalResponse || 0);
  }, [getEntitiesTotalResponse]);

  useEffect(() => {
    setLicenseTypes(getLicensedTypesResponse || []);
  }, [getLicensedTypesResponse]);

  useEffect(() => {
    setEntityCountries(getLicensedCountriesResponse || []);
  }, [getLicensedCountriesResponse]);

  const headerTitles = [
    "Entity Name",
    "Reference Number",
    "Country",
    "Licence Type",
    "Registration Date",
  ];

  const renderTableHeader = () => (
    <tr>
      {headerTitles.map((e) => (
        <th key={e}>
          <span>{e}</span>
        </th>
      ))}
    </tr>
  );

  const renderTableContent = () =>
    entities.map((e) => (
      <tr key={e._id}>
        <td>{e.Name}</td>
        <td>{e.RegistrationNumber}</td>
        <td>{e.Country}</td>
        <td>{e.LicenseType}</td>
        <td>{e.RegistrationDate}</td>
      </tr>
    ));

  const getLastPage = () => Math.ceil(totalCount / TABLE_RESULT_LIMIT) || 1;

  const handleFilterChange = (key, value) => {
    switch (key) {
      case "search_name":
        setSearchNameQuery(value.trim());
        break;
      case "reference_number":
        setSearchReferenceNumber(value.trim());
        break;
      case "license_type":
        setLicenseType(value.trim());
        break;
      case "country":
        setEntityCountry(value.trim());
        break;
      default:
        return null;
    }

    currentPage !== 1 && setPage(1);
  };

  const shouldRenderEmptyContainer = () =>
    !getEntitiesLoader && !entities.length;

  const isLoader = () => getEntitiesLoader || getEntitiesTotalLoader;

  const isGlobalLoader = () =>
    getLicensedCountriesLoader || getEntityLicenseLoader;

  const getTotalCountString = () => {
    const fromValue = (currentPage - 1) * TABLE_RESULT_LIMIT + 1;
    const toValue = (currentPage - 1) * TABLE_RESULT_LIMIT + entities.length;

    return (
      <p>{`Showing ${fromValue} to ${toValue} of ${totalCount} entities`}</p>
    );
  };

  return isGlobalLoader() ? (
    <div className="w-100 d-flex justify-content-center mt-3">
      <Spinner style={{ width: "3rem", height: "3rem", color: "#213731" }} />
    </div>
  ) : (
    <div className="section-log pt-3 pb-5">
      <div className="container content-card">
        <div className="margin-bottom-70 margin-top-40">
          <div className="col-12 text-left">
            <h2>Licenced Entities</h2>
            <p>
              Please find a list of all licenced or regulated entities in the
              Bahamas, Bermuda, BVI and the Cayman Islands.
            </p>

            <div className="divider" />

            <FilterBar
              entityCountries={entityCountries}
              licenseTypes={licenseTypes}
              onChange={debounce(handleFilterChange, SEARCH_DEBOUNCE_TIMEOUT)}
              licenseTypeValue={licenseType}
              countryValue={entityCountry}
            />

            <div className="d-flex justify-content-between mt-2">
              {getTotalCountString()}

              <TablePagination
                currentPage={currentPage}
                lastPage={getLastPage()}
                onPageSelected={(page) => setPage(page)}
              />
            </div>

            <Table className="stylish-table mt-3" responsive>
              <thead>{renderTableHeader()}</thead>
              <tbody>
                {isLoader() ? (
                  <TableLoader colSpan={5} />
                ) : (
                  <>
                    {shouldRenderEmptyContainer() && <TableEmpty colSpan={5} />}
                    {renderTableContent()}
                  </>
                )}
              </tbody>
            </Table>

            <div className="d-flex justify-content-end">
              <TablePagination
                currentPage={currentPage}
                lastPage={getLastPage()}
                onPageSelected={(page) => setPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entities;
