const TOKEN_KEY = "jwtToken";

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
  clearToken(tokenKey = TOKEN_KEY) {
    return localStorage.removeItem(tokenKey);
  },

  getToken(tokenKey = TOKEN_KEY) {
    return parse(localStorage.getItem(tokenKey)) || null;
  },

  setToken(value = "", tokenKey = TOKEN_KEY) {
    return localStorage.setItem(TOKEN_KEY, stringify(value));
  },
};

export default auth;
