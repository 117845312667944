import React from "react";

const SuccessContainer = () => (
  <>
    <h1 className="text-left mb-3">Thank you for your request!</h1>
    <p className="text-left mb-3">
      One of our team members will reach out to you shortly. 
    </p>
    <p className="text-left mb-3">In the mean time, please check out some other things by Cartan Group:
        <ul>
          <li><a href="https://cartan.group/what-we-do/economic-substance-services">Cartan Economic Substance Services</a></li>
          <li><a href="https://cartan.group/what-we-do/data-discovery-analytics">Data Discovery & Analytics Service</a></li>
          <li><a href="https://cartan.group/industry-insights">Cartan Industry Insights</a></li>
        </ul>
    </p>
  </>
);

export default SuccessContainer;
