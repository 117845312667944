import axios from "axios";

// helpers
import auth from "../utils/auth";

// constants
import { REQ_TIMEOUT } from "../config/constants";

const instance = axios.create({ timeout: REQ_TIMEOUT });

// add token to headers
instance.interceptors.request.use(function (config) {
  const token = auth.getToken();
  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;
