import React, { useState } from "react";

// helpers
import { useHistory } from "react-router-dom";

// components
import { Table } from "reactstrap";

import "./styles.scss";
import "./country-table-styles.scss";

const CountryTable = ({ country, data }) => {
  const FULL_MODE_THRESHOLD = 10;
  const history = useHistory();
  const [isFullMode, setFullMode] = useState(
    !!(data && data.length > FULL_MODE_THRESHOLD)
  );

  const renderTableContent = () => {
    const dataToRender = isFullMode
      ? [...data.data]
      : data.data.slice(0, FULL_MODE_THRESHOLD);
    return dataToRender.map(({ name, count }, i) => (
      <tr key={i}>
        <td className="text-left">
          <div
            className="link-text"
            onClick={() =>
              history.push({
                pathname: "/entities",
                search: `?license-type=${name}`,
              })
            }
          >
            {name}
          </div>
        </td>
        <td className="text-right">{count}</td>
      </tr>
    ));
  };

  const canRenderTable = () => !!(data && data.data.length);
  const shouldRenderShowMore = () => data.data.length > FULL_MODE_THRESHOLD;

  return (
    <div className="container country-table-card">
      <div className="country-table-card_heading">{country}</div>
      <div className="country-table-card_content">
        <Table className="countries-table" responsive>
          <thead>
            <tr>
              <th className="text-left">
                <span>Licence Type</span>
              </th>
              <th className="text-right">
                <span>Total</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {canRenderTable() && renderTableContent()}

            {shouldRenderShowMore() && (
              <tr className="show-more-row">
                <td colSpan="2">
                  <span onClick={() => setFullMode((prevValue) => !prevValue)}>
                    {isFullMode ? "Show less" : "Show more"}

                    <i
                      className={`fa ${
                        isFullMode ? "fa-angle-up" : "fa-angle-down"
                      }`}
                      aria-hidden="true"
                    />
                  </span>
                </td>
              </tr>
            )}

            {canRenderTable() && (
              <tr className="footer-total-row">
                <td className="text-left text-bold">
                  <span>Total Licences:</span>
                </td>
                <td className="text-right text-bold">
                  <span>{data.total}</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default CountryTable;
