import React from "react";

// components
import { Label, Input } from "reactstrap";

const FilterBar = ({
  onChange,
  licenseTypes,
  entityCountries,
  licenseTypeValue,
  countryValue,
}) => (
  <>
    <div className="row">
      <div className="col-xl-3 col-lg-6 col-md-12">
        <Label className="text-bold">Search by Name</Label>
        <Input
          type="text"
          placeholder="Enter entity name"
          onChange={(e) => onChange("search_name", e.target.value || "")}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-md-12">
        <Label className="text-bold">Search by Reference Number</Label>
        <Input
          type="text"
          placeholder="Enter reference number"
          onChange={(e) => onChange("reference_number", e.target.value || "")}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-md-12">
        <Label className="text-bold">Filter by Country</Label>
        <Input
          type="select"
          value={countryValue}
          onChange={(e) => onChange("country", e.target.value || "")}
        >
          <option key="" value="">
            All countries
          </option>

          {entityCountries.map((e) => (
            <option key={e}>{e}</option>
          ))}
        </Input>
      </div>

      <div className="col-xl-3 col-lg-6 col-md-12">
        <Label className="text-bold">Filter by Licence Type</Label>
        <Input
          type="select"
          value={licenseTypeValue}
          onChange={(e) => onChange("license_type", e.target.value || "")}
        >
          <option key="" value="">
            All licence types
          </option>

          {licenseTypes.map((e) => (
            <option key={e}>{e}</option>
          ))}
        </Input>
      </div>
    </div>
  </>
);

export default FilterBar;
