import React from "react";

import { Spinner } from "reactstrap";

const TableLoader = ({ colSpan }) => (
  <tr className="w-100">
    <td colSpan={colSpan} style={{ textAlign: "center" }}>
      <Spinner style={{ width: "3rem", height: "3rem", color: "#213731" }} />
    </td>
  </tr>
);

export default TableLoader;
