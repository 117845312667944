import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().trim().email("Wrong email").required("Enter email"),
  password: yup.string().trim().required("Enter password"),
});

export const requestAccessSchema = yup.object().shape({
  name: yup.string().trim().required("Enter name"),
  company: yup.string().trim().required("Enter company"),
  phone: yup.string().trim().required("Enter phone"),
  email: yup.string().trim().email("Wrong email").required("Enter email"),
  password: yup.string().min(6, "Short password").required("Enter password"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Wrong confirm password")
    .min(6, "Short password")
    .required("Enter password"),
});

export const forgotPasswordEmailStepSchema = yup.object().shape({
  email: yup.string().trim().email("Wrong email").required("Enter email"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup.string().min(6, "Short password").required("Enter password"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Wrong confirm password")
    .min(6, "Short password")
    .required("Enter password"),
});
