import React, { useContext, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { requestAccessSchema } from "../../../validation/auth";
import { AuthContext } from "../../../context/auth-context";
import { authAPI, authAPIHelpers } from "../../../api/authAPI";

// components
import InnerForm from "./inner-form";
import { Button, Alert } from "reactstrap";
import SuccessContainer from "./success-container";

const RequestAccessForm = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const { requestAccessLoading, requestAccessError } = state;

  useEffect(() => {
    dispatch({ type: "INIT_AUTH_CONTEXT" });
  }, []);

  const handleSubmit = async (values, helpers) => {
    dispatch({ type: "REQUEST_ACCESS_REQUEST" });

    try {
      await authAPI.requestAccess(authAPIHelpers.requestAccess(values));
      dispatch({ type: "REQUEST_ACCESS_SUCCESS" });
      helpers.resetForm();
      setSubmitted(true);
    } catch (err) {
      if (err && err.response) {
        const { response } = err;
        const errorMessage = response.data.message[0].messages[0].message;
        dispatch({
          type: "REQUEST_ACCESS_ERROR",
          payload: { error: errorMessage },
        });
      }
    }
  };

  const formInitialValues = {
    name: "",
    company: "",
    phone: "",
    email: "",
    addNewsLetter: true,
    password: "",
    passwordConfirm: "",
  };

  return (
    <div className="auth-card request-access-card mb-3">
      <Formik
        className="login-card"
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        validationSchema={requestAccessSchema}
      >
        {() => (
          <Form>
            <div className="login-card_heading">Request Access</div>

            <div className="login-card_content">
              {requestAccessError && (
                <Alert color="danger">{requestAccessError}</Alert>
              )}

              {isSubmitted ? (
                <SuccessContainer />
              ) : (
                <InnerForm isLoading={requestAccessLoading} />
              )}
            </div>

            {!isSubmitted && (
              <div className="login-card_footer">
                <Button
                  disabled={requestAccessLoading}
                  size="md"
                  type="submit"
                  color="primary"
                  className="submit-button"
                >
                  Request Access
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestAccessForm;
