import React, { useContext } from "react";

// form
import { forgotPasswordEmailStepSchema } from "../../../../validation/auth";

// helpers
import { Formik, Form } from "formik";
import { authAPI } from "../../../../api/authAPI";
import { AuthContext } from "../../../../context/auth-context";

// components
import { Button, Alert } from "reactstrap";
import InnerForm from "./innder-form";

const FirstStep = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { requestForgotPasswordLoading, requestForgotPasswordError } = state;

  const handleSubmit = async (values) => {
    dispatch({ type: "REQUEST_FORGOT_PASSWORD" });

    try {
      await authAPI.forgotPasswordSendEmail(values);
      dispatch({ type: "REQUEST_FORGOT_PASSWORD_SUCCESS" });
    } catch (err) {
      if (err && err.response) {
        const { response } = err;
        const error = response.data.message[0].messages[0].message;

        dispatch({
          type: "REQUEST_FORGOT_PASSWORD_ERROR",
          payload: { error },
        });
      }
    }
  };

  const formInitialValues = { email: "" };

  return (
    <div className="auth-card forgot-password-card ">
      <div className="login-card_heading">Forgot password</div>

      <Formik
        className="login-card"
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        validationSchema={forgotPasswordEmailStepSchema}
      >
        {() => (
          <Form>
            <div className="login-card_content">
              <div className="card-text mb-3">
                To reset your password, enter the email address you use to login
                to <span className="text-bold">360.cartan.group</span>.
              </div>

              {requestForgotPasswordError && (
                <Alert color="danger">{requestForgotPasswordError}</Alert>
              )}

              <InnerForm loading={requestForgotPasswordLoading} />
            </div>

            <div className="login-card_footer">
              <Button
                disabled={requestForgotPasswordLoading}
                size="md"
                type="submit"
                color="primary"
                className="submit-button"
              >
                Get Reset Link
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FirstStep;
