import React, { createContext, useReducer } from "react";

const initialState = {
  initialized: false,
  user: null,
  token: null,
  loading: false,
  errorMessage: null,

  getMeLoading: false,
  getMeError: null,

  requestAccessLoading: false,
  requestAccessError: null,

  forgotPasswordStep: 1,
  requestForgotPasswordLoading: false,
  requestForgotPasswordError: null,

  resetPasswordCode: null,
  requestResetPasswordLoader: false,
  requestResetPasswordError: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "INIT_AUTH_CONTEXT":
      return {
        ...initialState,
        errorMessage: null,

        getMeLoading: false,
        getMeError: null,

        requestAccessLoading: false,
        requestAccessError: null,

        forgotPasswordStep: 1,
        requestForgotPasswordLoading: false,
        requestForgotPasswordError: null,

        resetPasswordCode: null,
        requestResetPasswordLoader: false,
        requestResetPasswordError: null,
      };
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        errorMessage: null,
        loading: true,
      };

    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.payload.error,
      };

    case "LOGOUT":
      return {
        ...initialState,
        user: null,
        token: null,
      };

    case "GET_ME_REQUEST":
      return {
        ...initialState,
        getMeError: null,
        getMeLoading: true,
      };

    case "GET_ME_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        getMeLoading: false,
      };

    case "GET_ME_ERROR":
      return {
        ...initialState,
        getMeLoading: false,
        getMeError: action.payload.error,
      };

    case "REQUEST_ACCESS_REQUEST":
      return {
        ...initialState,
        requestAccessError: null,
        requestAccessLoading: true,
      };

    case "REQUEST_ACCESS_ERROR":
      return {
        ...initialState,
        requestAccessError: action.payload.error,
        requestAccessLoading: false,
      };

    case "REQUEST_ACCESS_SUCCESS":
      return {
        ...initialState,
        requestAccessLoading: false,
      };

    case "SET_FORGOT_PASSWORD_STEP":
      return {
        ...initialState,
        forgotPasswordStep: action.payload.step,
        resetPasswordCode: action.payload.code || null,
        requestForgotPasswordLoading: false,
        requestForgotPasswordError: null,
      };

    case "REQUEST_FORGOT_PASSWORD":
      return {
        ...initialState,
        requestForgotPasswordLoading: true,
        requestForgotPasswordError: null,
        resetPasswordCode: null,
      };

    case "REQUEST_FORGOT_PASSWORD_ERROR":
      return {
        ...initialState,
        requestForgotPasswordLoading: false,
        requestForgotPasswordError: action.payload.error,
        resetPasswordCode: null,
      };

    case "REQUEST_FORGOT_PASSWORD_SUCCESS":
      return {
        ...initialState,
        requestForgotPasswordLoading: false,
        requestForgotPasswordError: null,
        forgotPasswordStep: 2,
        resetPasswordCode: null,
      };

    case "REQUEST_RESET_PASSWORD":
      return {
        ...initialState,
        requestResetPasswordLoader: true,
        requestResetPasswordError: null,
      };

    case "REQUEST_RESET_PASSWORD_SUCCESS":
      return {
        ...initialState,
        resetPasswordCode: null,
        requestResetPasswordLoader: false,
        requestResetPasswordError: null,
      };

    case "REQUEST_RESET_PASSWORD_ERROR":
      return {
        ...initialState,
        requestResetPasswordLoader: false,
        requestResetPasswordError: action.payload.error,
      };

    case "SET_INITIALIZED":
      return { ...initialState, initialized: action.payload.status };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const AuthContext = createContext(initialState);

const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
