import React from "react";

import "./style.scss";

const Footer = () => (
  <section className="footer-container">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-8 footer-logo-wrapper">
          <h4 className="footer-logo">CARTAN</h4>
        </div>

        <div className="col-12 col-md-4 text-md-right">
          <ul className="list-horizontal-unstyled">
            <li>
              <a
                className="text-btn-3"
                href="https://www.cartan.group/who-we-are/contact-us"
              >
                Ask Cartan
              </a>
            </li>
            <li>
              <a href="https://twitter.com/cartangroup" rel="noreferrer" target="_blank">
                <i className="fa fa-twitter white-icon"></i>
              </a>
            </li>
            <li>
              <a
                href="https://linkedin.com/company/cartangroup" rel="noreferrer" 
                target="_blank"
              >
                <i className="fa fa-linkedin white-icon"></i>
              </a>
            </li>
            <li>
              <a href="https://instagram.com/cartangroup" rel="noreferrer" target="_blank">
                <i className="fa fa-instagram white-icon"></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/cartangroup" rel="noreferrer" target="_blank">
                <i className="fa fa-facebook white-icon"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row footer-info-container">
        <div className="col-12">
          <ul className="list-horizontal-dash font-small">
            <li>
              <a href="https://cartan.group/what-we-do">What We Do</a>
            </li>
            <li>
              <a href="https://cartan.group/who-we-are">Who We Are</a>
            </li>
            <li>
              <a href="https://cartan.group/community-commitment">Community Commitment</a>
            </li>
            <li>
              <a href="https://cartan.group/industry-insights">Industry Insights</a>
            </li>
          </ul>
          <ul className="margin-top-10 list-horizontal-dash font-small">
            <li>
              <a href="https://cartan.group/policies/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="https://cartan.group/policies/terms"> Terms of Use</a>
            </li>
            <li>
              <a href="https://cartan.group/policies/cookies"> Cookie Policy</a>
            </li>
          </ul>
          <p className="margin-top-10 font-small"></p>
          <p>© Cartan Group LLC, All Rights Reserved.</p>
          <p></p>
        </div>
      </div>
    </div>
  </section>
);

export default Footer;
