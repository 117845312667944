import React, { useState, useEffect } from "react";

import hero_img from "../../assets/images/360.jpg";
import section_img from "../../assets/images/cartan-data-discovery.jpg";
import "./styles.css";

import useFetch from "../../hooks/useFetch";
import { entitiesAPI } from "../../api/entitiesAPI";
import { Spinner } from "reactstrap";

import screen1 from './screen1.png';
import screen2 from './screen2.png';
import screen3 from './screen3.png';

const Home = () => {
  const [countries, setCountries] = useState(null);

  const {
    response: getCountriesResponse,
    loading: getCountriesLoader,
  } = useFetch(() => entitiesAPI.getHome());

  useEffect(() => {
    setCountries(getCountriesResponse || []);
  }, [getCountriesResponse]);

  let style = {
    "background-image": `url(${hero_img})`,
    "background-size": "cover",
    "background-attachment": "fixed",
    "background-position": "center 0px",
    "background-repeat": "no-repeat no-repeat",
    height: "700px",
  };
  let ctaSectionStyle = {
    "background-image": `url(${section_img})`,
    "background-size": "cover",
    "background-attachment": "fixed",
    "background-position": "center 0px",
    "background-repeat": "no-repeat no-repeat",
  };

  const renderCountries = () => {
    if (!countries) return null;

    return Object.keys(countries)
      .sort()
      .map((key) => {
        const { totalLicenceTypes, totalLicences } = countries[key];

        return (
          <div className="col-12 col-md country-card-wrapper-test">
            <div className="country-card" key={key}>
              <h5 className="font-weight-normal">{key}</h5>
              <p>{`Licence Types: ${totalLicenceTypes}`}</p>
              <p>{`Total Licences: ${totalLicences}`}</p>
            </div>
          </div>
        );
      });
  };

  return (
    <>
      <div className="section-2xl bg-image parallax" style={style}>
        <div className="bg-black-01">
          <div className="container text-center">
            <div className="row ">
              <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 margin-top-200">
                <h1 className="font-weight-light margin-top-50">
                  360&deg;
                  <sub>
                    <small>beta</small>
                  </sub>{" "}
                  Industry Monitor
                </h1>
                <p className="font-weight-normal line-height-140 margin-bottom-20">
                  Data monitoring & reporting for <br /> the Caribbean financial
                  services industry
                </p>
                <a
                  className="button button-outline-white-2 margin-bottom-10 padding-10"
                  href="/auth/request-access"
                >
                  Request Access
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section border-bottom">
        <div className="container">
          <h2 className="display-4 margin-bottom-40">
            Cartan Data Discovery & Analytics
          </h2>
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="font-weight-light margin-0">
                Our data team at Cartan Group has deployed a data mining,
                monitoring, and analysis tool that is rapidly evolving and
                developing new financial services industry insights.
              </h5>
            </div>
            <div className="col-12 col-md-4">
              <p>
                As digitization encourages further international commercial
                growth, it is becoming more important to have access to smart
                data across key jurisdictions. The Cartan 360&deg;
                <sub>beta</sub> Industry Monitor is powered by a growing network
                of learning algorithms that continually discover, categorize,
                and analyze industry data.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <p>
                We are currently providing early access to select clients who
                are seeking better data to capitalize on industry trends, market
                shifts, and opportunities.{" "}
              </p>
              <a
                className="text-btn font-weight-100 margin-top-20"
                href="/auth/request-access"
              >
                Request Beta Access
              </a>
            </div>
          </div>
          <hr />
          <div className="gallery-wrapper spacing-20 row">
          <div className="col-12 col-md-4">
              <img src={screen3} alt="" />
            </div>
            <div className="col-12 col-md-4">
              <img src={screen1} alt="" />
            </div>
            <div className="col-12 col-md-4">
              <img src={screen2} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-image parallax" style={ctaSectionStyle}>
        <div className="bg-black-01">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <h1 className="font-weight-light">
                  Do you want to see how data can help your company make
                  informed strategic decisions?
                </h1>
                <a
                  className="button button-white button-xl margin-bottom-10"
                  href="https://cartan.group/who-we-are/contact-us"
                >
                  <i className="ti-arrow-right"></i>
                  <span>Ask Cartan</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section padding-top-0">
        <div className="container">
          <h2 className="display-4 margin-bottom-40">
            Currently Monitoring These Regions
          </h2>
          <p>
            Our 360&deg;<sub>beta</sub> Industry Monitor is currently gathering
            and analyzing publicly available data from the jurisdictions listed
            below. We are searching, crawling, and storing an exponential amount
            of new data everyday.
          </p>

          <div className="row icon-5xl d-flex align-items-center mt-3">
            {getCountriesLoader ? (
              <div className="w-100 d-flex justify-content-center pt-3 pb-3">
                <Spinner
                  style={{ width: "3rem", height: "3rem", color: "#213731" }}
                />
              </div>
            ) : (
              renderCountries()
            )}
          </div>
          <div className="box-shadow padding-30 margin-top-50 bg-cartan-green">
            <h3 className="display-5 text-white">
              360&deg;
              <sub>
                <small>beta</small>
              </sub>{" "}
              &nbsp;Access
            </h3>
            <p className=" text-white text-left">
              Our early access 360&deg;<sub>beta</sub> Industry Monitor is
              currently providing a small subset of the data currently being
              collected. New features, jurisdictional datasets, industry
              insights and trends will be provided to early users allowing them
              to spot market opportunities before their competitors.{" "}
            </p>
            <a
              className="text-right margin-top-30 text-btn-3"
              href="/auth/request-access"
            >
              Request Access
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
