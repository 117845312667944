import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// This hook is responsible for searching the current page title
const useBrowserTabTitle = (isAuthorized) => {
  const location = useLocation();
  const [browserTabTitle, setBrowserTabTitle] = useState("");

  // Updated browser tab title if the current path has been changed
  useEffect(() => {
    const newTitle = getPageTitle(location.pathname);

    setBrowserTabTitle(newTitle);
  }, [location.pathname]);

  const getPageTitle = (path) => {
    const appTitle = "Cartan 360 Industry Monitor";
    const requestAccessString = "Access Request";

    let result = "";

    switch (path) {
      case "/":
        result = `Home${!isAuthorized ? ` | ${requestAccessString}` : ""}`;
        break;

      case "/entities":
        result = `Entities${!isAuthorized ? ` | ${requestAccessString}` : ""}`;
        break;

      case "/dashboard":
        result = `Dashboard${!isAuthorized ? ` | ${requestAccessString}` : ""}`;
        break;

      case "/auth/login":
        result = "Login";
        break;

      case "/auth/request-access":
        result = "Request Access";
        break;

      default:
        result = "";
        break;
    }

    return `${result} | ${appTitle}`;
  };

  return browserTabTitle;
};

export default useBrowserTabTitle;
