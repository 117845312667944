import React from "react";

// helpers
import { useHistory } from "react-router-dom";

const HeaderCard = ({ countryName, count, grandTotal }) => {
  const history = useHistory();

  const onNameClick = () => {
    history.push({
      pathname: "/entities",
      search: `?country=${countryName}`,
    });
  };
  return (
    <div className="header-card-wrapper col">
      <div className="header-card">
        <div className="header-card_heading link-text" onClick={onNameClick}>
          {countryName}
        </div>
        <div className="header-card_content">
          <div className="header-card_content__sub">
            <div className="sub-title">Licence Types:</div>
            <div className="sub-value">{count}</div>
          </div>

          <div className="header-card_content__sub">
            <div className="sub-title">Total Licences:</div>
            <div className="sub-value">{grandTotal}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCard;
