import React, { useContext, useState } from "react";

import { authAPI } from "../../../../api/authAPI";
import { Formik, Form } from "formik";
import { resetPasswordSchema } from "../../../../validation/auth";
import { AuthContext } from "../../../../context/auth-context";
import InnerForm from "./inner-form";
import SuccessContainer from "./success-container";

// components
import { Button, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";

const ResetPassword = () => {
  const history = useHistory();
  const [isSubmitted, setSubmitted] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const {
    resetPasswordCode,
    requestResetPasswordLoader,
    requestResetPasswordError,
  } = state;

  const handleSubmit = async (values) => {
    const body = {
      code: resetPasswordCode,
      password: values.password,
      passwordConfirmation: values.passwordConfirm,
    };

    dispatch({ type: "REQUEST_RESET_PASSWORD" });

    try {
      await authAPI.resetPassword(body);
      dispatch({ type: "REQUEST_RESET_PASSWORD_SUCCESS" });
      setSubmitted(true);
    } catch (err) {
      if (err && err.response) {
        const { response } = err;
        const errorMessage = response.data.message[0].messages[0].message;
        dispatch({
          type: "REQUEST_RESET_PASSWORD_ERROR",
          payload: { error: errorMessage },
        });
      }
    }
  };

  const formInitialValues = {
    password: "",
    passwordConfirm: "",
  };

  return (
    <div className="auth-card reset-password-card">
      <Formik
        className="login-card"
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        validationSchema={resetPasswordSchema}
      >
        {() => (
          <Form>
            <div className="login-card_heading">
              {isSubmitted ? "Successful password reset!" : "Reset password"}
            </div>

            <div className="login-card_content">
              {requestResetPasswordError && (
                <Alert color="danger">{requestResetPasswordError}</Alert>
              )}

              {isSubmitted ? (
                <SuccessContainer />
              ) : (
                <InnerForm isLoading={requestResetPasswordLoader} />
              )}
            </div>

            <div className="login-card_footer">
              {isSubmitted ? (
                <Button
                  disabled={requestResetPasswordLoader}
                  size="md"
                  color="primary"
                  className="submit-button"
                  onClick={() => history.push("/auth/login")}
                >
                  Login
                </Button>
              ) : (
                <Button
                  disabled={requestResetPasswordLoader}
                  size="md"
                  type="submit"
                  color="primary"
                  className="submit-button"
                >
                  Reset password
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
