import React, { useContext, useEffect } from "react";

// helpers
import auth from "../../../utils/auth";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import { loginSchema } from "../../../validation/auth";
import { authAPIHelpers, authAPI } from "../../../api/authAPI";

// components
import FormInput from "../../../components/FormInput";
import { Button, Input, Label, Alert } from "reactstrap";

const LoginForm = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);
  const { loading, errorMessage } = state;

  useEffect(() => {
    dispatch({ type: "INIT_AUTH_CONTEXT" });
  }, []);

  const handleSubmit = async (values) => {
    dispatch({ type: "REQUEST_LOGIN" });

    try {
      const response = await authAPI.login(
        authAPIHelpers.login({ ...values, url: "http://localhost:3000/" })
      );
      if (response.user.approved) {
        auth.setToken(response.jwt);
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { user: response.user, token: response.jwt },
        });
        history.push("/dashboard");
      } else {
        dispatch({
          type: "LOGIN_ERROR",
          payload: {
            error:
              "Your account has to be confirmed by an administrator before you can login.",
          },
        });
      }
    } catch (err) {
      if (err && err.response) {
        const { response } = err;
        const errorMessage = response.data.message[0].messages[0].message;
        dispatch({ type: "LOGIN_ERROR", payload: { error: errorMessage } });
      }
    }
  };

  const formInitialValues = { email: "", password: "" };

  const navigateToForgotPassword = () => {
    history.push("/auth/forgot-password");
  };

  return (
    <div className="auth-card login-card margin-bottom-40">
      <Formik
        className="login-card"
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        validationSchema={loginSchema}
      >
        {() => (
          <Form>
            <div className="login-card_heading">Login</div>

            <div className="login-card_content">
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

              <div className="form-field-wrapper">
                <Label>Email</Label>
                <FormInput
                  disabled={loading}
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  component={Input}
                />
              </div>

              <div className="form-field-wrapper">
                <Label>Password</Label>
                <FormInput
                  disabled={loading}
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  component={Input}
                />
              </div>
            </div>

            <div className="login-card_footer">
              <Button
                disabled={loading}
                size="md"
                type="submit"
                color="primary"
                className="submit-button"
              >
                Login
              </Button>
            </div>

            <div
              className="forgot-password-link  mb-2"
              onClick={navigateToForgotPassword}
            >
              Forgot your password?
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
