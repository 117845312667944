import React from "react";
import { useHistory } from "react-router-dom";

const FirstStepSuccess = () => {
  const history = useHistory();

  const navigateToLogin = () => history.push("/auth/login");

  return (
    <>
      <div className="auth-card forgot-password-card ">
        <div className="login-card_heading">Email has been sent!</div>

        <div className="login-card_content">
          <div className="card-text mb-3">
            Please check your inbox and click in the received link to reset a
            password.
          </div>
        </div>

        <div
          className="forgot-password-link mb-2 mt-3"
          onClick={navigateToLogin}
        >
          Go back to Login
        </div>
      </div>
    </>
  );
};

export default FirstStepSuccess;
