import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";

const PrivateRoute = ({ component: Component, isAuth, ...rest }) => {
  const { state } = useContext(AuthContext);

  const isAuthorized = state.token && state.user;
  const redirectToPath = isAuthorized ? "/" : "/auth/request-access";

  return isAuthorized && !isAuth ? (
    <Route render={(props) => <Component {...props} />} {...rest} />
  ) : !isAuthorized && isAuth ? (
    <Route render={(props) => <Component {...props} />} {...rest} />
  ) : (
    <Route
      render={(props) => <Redirect to={redirectToPath} {...props} />}
      {...rest}
    />
  );
};

export default PrivateRoute;
