import React from "react";

import "./styles.scss";

const TableEmpty = ({ colSpan }) => (
  <tr className="tableEmpty">
    <td colSpan={colSpan}>Empty data ...</td>
  </tr>
);

export default TableEmpty;
