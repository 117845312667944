import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/app/app.js";
import { BrowserRouter } from "react-router-dom";

// style
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/styles/site.scss";
import "font-awesome/css/font-awesome.min.css";

import { AuthContextProvider } from "./context/auth-context/index.js";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
