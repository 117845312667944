import { useState, useEffect } from "react";

const useFetch = (callback, dependencies) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const doFetch = async () => {
        setLoading(true);
        try {
          const res = await callback();
          if (!signal.aborted) {
            setResponse(res);
          }
        } catch (e) {
          if (!signal.aborted) {
            setError(e);
          }
        } finally {
          if (!signal.aborted) {
            setLoading(false);
          }
        }
      };
      callback && doFetch();
      return () => {
        abortController.abort();
      };
    },
    dependencies ? [...dependencies] : []
  );
  return { response, error, loading };
};

export default useFetch;
