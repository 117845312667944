import React from "react";

// components
import FormInput from "../../../../components/FormInput";
import { Input, Label } from "reactstrap";

const InnerForm = ({ isLoading }) => (
  <>
    <div className="mb-2">
      <div className="form-field-wrapper">
        <Label>Password</Label>
        <FormInput
          disabled={isLoading}
          type="password"
          name="password"
          placeholder="Enter password"
          component={Input}
        />
      </div>

      <div className="form-field-wrapper">
        <Label>Confirm Password</Label>
        <FormInput
          disabled={isLoading}
          type="password"
          name="passwordConfirm"
          placeholder="Enter password confirm"
          component={Input}
        />
      </div>
    </div>
  </>
);

export default InnerForm;
