import React, { useState, useEffect } from "react";

// helpers
import useFetch from "../../hooks/useFetch";
import { entitiesAPI } from "../../api/entitiesAPI";

// components
import { Spinner } from "reactstrap";
import CountryTable from "./country-table";
import HeaderCard from "./header-card";

import "./header-card-styles.scss";

const Dashboard = () => {
  const [countries, setCountries] = useState(null);

  const {
    response: getCountriesResponse,
    loading: getCountriesLoader,
  } = useFetch(() => entitiesAPI.getLicenseTypesByCountry());

  useEffect(() => {
    if (getCountriesResponse) {
      let formattedResponse = {};

      Object.keys(getCountriesResponse).forEach((key) => {
        let filteredCountries = getCountriesResponse[key].data.slice();

        filteredCountries = filteredCountries.sort((a, b) =>
          a.count > b.count ? -1 : 1
        );

        formattedResponse[key] = {
          data: filteredCountries.slice(),
          total: getCountriesResponse[key].total,
        };
      });

      setCountries(formattedResponse || []);
    }
  }, [getCountriesResponse]);

  const renderCountries = () => {
    if (!countries || !Object.keys(countries).length) return;
    return Object.keys(countries)
      .sort()
      .map((key) => (
        <div className="container content-card-wrapper col-12 mb-3">
          <CountryTable country={key} data={countries[key]} />
        </div>
      ));
  };

  const renderHeader = () => {
    if (!countries || !Object.keys(countries).length) return;
    return Object.keys(countries)
      .sort()
      .map((key) => (
        <HeaderCard
          countryName={key}
          count={countries[key].data.length}
          grandTotal={countries[key].total}
        />
      ));
  };

  return (
    <div className="row content-row pt-3 pb-5">
      {getCountriesLoader && (
        <div className="w-100 d-flex justify-content-center">
          <Spinner
            style={{ width: "3rem", height: "3rem", color: "#213731" }}
          />
        </div>
      )}

      <div className="container header-section">{renderHeader()}</div>
      {renderCountries()}
    </div>
  );
};

export default Dashboard;
