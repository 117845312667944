import React from "react";

import { Route } from "react-router-dom";

// form components
import LoginForm from "./login-form";
import RequestAccessForm from "./request-access-form";
import ForgotPassword from "./forgot-password";

// styles
import "./styles.scss";

const AuthPage = () => {
  return (
    <div className="auth-root">
      <Route path="/auth/login" component={LoginForm} exact />
      <Route path="/auth/request-access" component={RequestAccessForm} exact />
      <Route path="/auth/forgot-password" component={ForgotPassword} exact />
    </div>
  );
};

export default AuthPage;
