import { apiConfig } from "../config/api-config";
import axiosInstance from "./axios-instance";

const entitiesAPI = {
  getEntities: (params) => {
    return axiosInstance
      .get(`${apiConfig.apiGateway}/licensed-entities`, { params })
      .then((response) => response.data);
  },

  getEntitiesTotal: (params) => {
    return axiosInstance
      .get(`${apiConfig.apiGateway}/licensed-entities/count`, { params })
      .then((response) => response.data);
  },

  getEntityLicenseTypes: () => {
    return axiosInstance
      .get(`${apiConfig.apiGateway}/licensed-types`)
      .then((response) => response.data);
  },

  getEntityLicenseCountries: () => {
    return axiosInstance
      .get(`${apiConfig.apiGateway}/licensed-countries`)
      .then((response) => response.data);
  },

  getLicenseTypesByCountry: () => {
    return axiosInstance
      .get(`${apiConfig.apiGateway}/dashboard`)
      .then((response) => response.data);
  },

  getHome: () => {
    return axiosInstance
      .get(`${apiConfig.apiGateway}/home`)
      .then((response) => response.data);
  },
};

export { entitiesAPI };
